import React from 'react';
import './App.css';
import Hyperdrive from 'hyperdrive-3d-scroller'
import particles from 'particles.js'
import particlesJson from './particles.json'
import cube from './images/cube.png'
import arrow from './images/arrow.png'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function App() {
  const [isMobile, setIsMobile] = React.useState(true)

  const maxValue = (variable, value) => {
    return (variable > value ? value : variable)
  }
  const minValue = (variable, value) => {
    return (variable < value ? value : variable)
  }
  const scrolling = () => {
    let topOfWindow = window.pageYOffset
    const scrollPoint = 500;
    const percentOfScrollPoint = topOfWindow/scrollPoint

    const app = document.querySelector('.App')
    const header = document.querySelector('#header')

    if(topOfWindow > scrollPoint){
      app.classList.add('scrolled')
    }else{
      app.classList.remove('scrolled')
    }

    app.style.setProperty('--opacity', maxValue(percentOfScrollPoint, 1))
    header.style.setProperty('--fontSizeMiddle', minValue((1-(percentOfScrollPoint)), 0))
    header.style.setProperty('--opacityMiddle', minValue((1.3-(percentOfScrollPoint)), 0))
    header.style.setProperty('--headerTop', minValue((10-(topOfWindow/30)), 0))
 }
 React.useEffect(() => {
    window.addEventListener('scroll', scrolling)

    let hd = new Hyperdrive()
    hd.initialize()

    if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      window.particlesJS('particles-js', particlesJson);
    }
  }, [])
  return (
    <div className="App">
      <header id="header">
        <h1 className="title"><span className="logo f">F</span><span className="middleTitle">OKUSIV</span><span className="logo dot">.</span>
        <span className="taglineWrap"><span className="tagline">Progressiv webb & apputveckling</span></span></h1>        
        <div className="scrollIndicator">
          scroll<br/>
          <KeyboardArrowDownIcon/>      
        </div>
      </header>
      <div className="hyperdrive" blur={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'false' : 'true')}>
          <div className="hyperdriveContainer">
              <div className="hyperdriveScene">
                  <div className="intro">
                      <h2>Se webben från vårat håll</h2>
                      <h3>Stockholms progressiva webbyrå</h3>
                  </div>
                  {(
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ?
                    null :
                    <div id="particles-js" className="particles"></div>
                  )}
                  <div className="twoColumn perspective" y-position="52%">
                      <div className="columnImage">
                        <img src={cube} alt="Impossible Cube"/>
                      </div>
                      <div className="columnText">
                        <h2>Nya perpektiv</h2>
                        <p>Med insikt i de senaste teknikerna inom utveckling kollar vi frammåt för att bygga framtidens webb med unika lösningar. <br/>Nya perspektiv ger nya möjligheter.</p>
                      </div>
                  </div>
                  <div className="twoColumn uppdrag" y-position="54%">
                      <div className="columnText">
                        <h2>Träffa rätt!</h2>
                        <p>Vare sig du har ett uppdrag eller behöver en konsult så vill vi höra om ditt projekt. Vi träffas gärna på en kopp kaffe och ser om ett samarbete skulle vara rätt.</p>
                      </div>
                      <div className="columnImage">
                        <img src={arrow} alt="Arrow"/>
                      </div>
                  </div>
                  <div>
                      <h2>Säg hej!</h2>
                      <p>
                        Vi vill gärna höra om dina projekt eller funderingar.<br/>
                        <a className="button outline" href="mailto:info@fokusiv.com">info@fokusiv.com</a>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default App;
